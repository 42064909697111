.error {
    border: 1px solid red;
}
.siginIn:hover {
    background-color: #800000 !important;
    color: white;
}
.mapboxgl-canvas {
    width: 100% !important;
    height: 100vh !important;
}
.css-xof2nj-MuiPaper-root-MuiCard-root {
    border-radius: 4px !important;
    border: 1px solid #364152;
}
.genericLink:hover {
    color: blue;
    text-decoration: underline;
}
.tss-1qtl85h-MUIDataTableBodyCell-root {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}

.css-1rmvxtn-MuiTableCell-root {
    border-bottom: 1px solid !important;
    text-wrap: nowrap;
    text-align: center;
}
.css-15jqzt-MuiTableCell-root {
    border-bottom: 1px solid !important;
    text-wrap: nowrap;
    text-align: center;
}
.tss-178gktx-MUIDataTableHeadCell-contentWrapper {
    justify-content: center;
}
.css-1ib0lrz-MuiTypography-root {
    color: inherit;
}
.css-nk42kx-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    max-height: 300px !important;
}
.css-35ypl7 {
    border: 1px solid !important;
}
.css-ifa4m9 {
    color: inherit !important;
}
.css-1xt2g5u {
    border-bottom: 1px solid !important;
}
.css-1i7m4fq {
    border-bottom: 1px solid !important;
}
.css-1xt2g5u {
    color: #364152 !important;
}
.css-1xhypcz-MuiStack-root > .MuiTextField-root {
    width: 174px !important;
    margin-left: 8px;
}

.css-4czd11-MuiTypography-root {
    font-size: 1rem !important;
}
.css-w3uok3-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10.5px 12px !important;
}
.css-w3uok3-MuiInputBase-input-MuiOutlinedInput-input {
    font: small-caption !important;
    font-size: 14px !important;
}
.css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {
    color: #800000 !important;
}
.css-1yxmbwk:hover {
    color: #800000 !important;
}
.css-lneya7 {
    padding: 0px !important;
}
.css-1n1b11-MuiPaper-root {
    margin: 20px !important;
}
.css-ohi407 {
    padding: 0px !important;
}
.mapboxgl-ctrl-top-right {
    bottom: 0 !important;
}
.css-u1wif5 {
    margin: 20px !important;
}
.css-i44wyl {
    margin-right: 16px;
}

.css-18vcbsr-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiInputBase-inputSizeSmall {
    display: flex !important;
}
.css-rb5ov.MuiInputBase-inputSizeSmall {
    display: flex !important;
}

.css-1g5w5mq {
    // font-size: 8px !important;
    padding: 11.5px 14px !important;
}
.css-i44wyl {
    margin-right: 8px !important;
    font-size: 8px !important;
    width: 200px !important;
}
.css-1fuv4p8 {
    margin-left: 0px !important;
}
.css-1fuv4p8 {
    width: 207px !important;
}
.css-1o3yml1 {
    height: 42px !important;
}
.css-rb5ov.css-rb5ov.css-rb5ov {
    display: flex !important;
}
.css-q6aqj {
    font-size: 1rem !important;
}
.print-only {
    display: none;
}
.css-8ybyx8-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
    max-height: 300px !important;
}
.css-g1ne49 {
    max-height: 300px !important;
}
.css-pidhby-MuiTableCell-root {
    padding: 0px 0px 0px 16px !important;
}
.css-1lmr29t-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #364152 !important;
}

.css-143mcme-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
    color: #364152 !important;
}

.css-1pduc5x-MuiStack-root {
    margin-right: 9px !important;
}
.css-w9q9sv-MuiGrid-root {
    padding: 19px 25px;
}
.cancel-btn:hover {
    background-color: #ff9922 !important;
}

.css-b3dvpx-MuiStack-root > .MuiTextField-root {
    min-width: 217.99px !important;
    width: 217.99px !important;
}
.css-b3dvpx-MuiStack-root {
    // margin-left: 10px !important;
}

.css-4jnixx-MuiStack-root {
    margin-left: 9px !important;
}
.css-1bdge0k-MuiButtonBase-root-MuiButton-root {
    margin-left: 10px !important;
}

.print-source {
    display: none;
}
body > .print-source {
    display: block;
}

@media print {
    .print-content {
        margin: 40px;
    }
    .print-only {
        display: block;
        margin: 40px;
        page-break-inside: avoid;
    }
}

@media print {
    .print-source {
        display: block;
    }
}

@media (min-width: 900px) {
    .css-qpq9bu {
        display: flex;
        justify-content: center !important;
        margin-right: 25px !important;
    }
}
